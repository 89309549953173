/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Logo from '../../assets/images/logo-light.png'
import WOW from 'wowjs'

const Navbar = () => {
    const location = useLocation()
    const path = location.pathname
    const [sticky, setSticky] = useState(false)
    // const [search, setSearch] = useState(false)

    const [menu, setmenu] = useState({})

    const activeMenu = () => {
        if(path === "/" || path === "/home-02" || path === "/home-03" ){
            setmenu({home:true})
        }else if(path === "/about" || path === "/team"){
            setmenu({pages:true})
        }else if(path === "/service-01" || path === "/service-02" || path === "qa-testing" || path === "/it-management" || path === "cyber-security" || path === "/it-consultant" || path === "/infrastructure-plan"){
            setmenu({services:true})
        }else if(path === "/industries" || path === "/project-details"){
            setmenu({project:true})
        }else if(path === "/careers" || path === "/blog-details"){
            setmenu({blog:true})
        }else if(path === "/contact"){
            setmenu({contact:true})
        }else{
            setmenu({home:true})
        }
    }

    useEffect(() => {
        window.addEventListener("scroll", isSticky);
        return () => {
            window.removeEventListener("scroll", isSticky);
        };
    }, []);

    useEffect(()=>{
        window.scroll(0, 0)
        new WOW.WOW({
          live: false
        }).init();
        activeMenu()
    }, [path])
    
    const isSticky = () => {
        const scrollTop = window.scrollY;
        scrollTop >= 141 ? setSticky(true) : setSticky(false);
    }
    return (
        <>
            <div className="topbar">
                <div className="container-fluid">
                    <p className="topbar__text">Welcome to state of the art waste management solution</p>
                    <ul className="topbar__info">
                        <li>
                            <i className="fa fa-envelope"></i>
                            <Link to="mailto:needhelp@company.com">needhelp@imgarbage.co.uk</Link>
                        </li>
                        <li>
                            <i className="fa fa-map-marker"></i>
                            84 Salop Street, WV3 0SR - Wolverhampton, UK
                        </li>
                    </ul>
                    <ul className="topbar__social">
                        <li><Link to="#"><i className="fab fa-twitter"></i></Link></li>
                        <li><Link to="#"><i className="fab fa-facebook"></i></Link></li>
                        {/*<li><Link to="#"><i className="fab fa-pinterest-p"></i></Link></li>*/}
                        <li><Link to="#"><i className="fab fa-instagram"></i></Link></li>
                    </ul>
                </div>
            </div>
            <nav className={`main-menu sticky-header ${sticky && "sticky-header--cloned sticky-fixed"}`}>
                <div className="container-fluid">
                    <div className="main-menu__logo">
                        <Link to="/">
                            <img src={Logo} height={50}   alt="I'm Garbage" />
                        </Link>
                    </div>

                    <ul className="main-menu__list">
                        <li className={`menu-item-has-children ${menu.home && "current"}`}>
                            <Link to="/">Home</Link>
                        </li>
                        {/*<li className={`menu-item-has-children ${menu.pages && "current"}`}>*/}
                        {/*    <Link to="#">About Us</Link>*/}
                        {/*    <ul>*/}
                        {/*        <li><Link to="/about">Our Story</Link></li>*/}
                        {/*        <li><Link to="/team">Company Values</Link></li>*/}
                        {/*        <li><Link to="/about">Team</Link></li>*/}
                        {/*    </ul>*/}
                        {/*</li>*/}
                        <li className={`menu-item-has-children ${menu.services && "current"}`}>
                            <Link to="#">Services</Link>
                            <ul>
                                <li><Link to="#">All Services</Link></li>
                                <li><Link to="#">Waste removal</Link></li>
                                <li><Link to="#">Dumpster rental</Link></li>
                                <li><Link to="#">On-Demand collection</Link></li>
                                <li><Link to="#">Autonomous collection</Link></li>
                                <li><Link to="#">Zero waste</Link></li>
                                <li><Link to="#">Technology</Link></li>
                            </ul>
                        </li>
                        <li className={`menu-item-has-children ${menu.project && "current"}`}>
                            <Link to="/industries">Industries</Link>
                            <ul>
                                <li><Link to="/industries">All Industries</Link></li>
                                <li><Link to="#">Grocery Stores</Link></li>
                                <li><Link to="#">Medical & Hospital</Link></li>
                                <li><Link to="#">Hotel & Restaurant</Link></li>
                                <li><Link to="#">Municipalities</Link></li>
                                <li><Link to="#">Mall & Theatre</Link></li>
                                <li><Link to="#">Stadium & Events</Link></li>
                            </ul>
                        </li>
                        <li className={`menu-item-has-children ${menu.blog && "current"}`}>
                            <Link to="/careers">Careers</Link>
{/*                            <ul>
                                <li><Link to="/blog">Blog</Link></li>
                                <li><Link to="/blog-details">Blog Details</Link></li>
                            </ul>*/}
                        </li>
                        <li className={`menu-item-has-children ${menu.contact && "current"}`}>
                            <Link to="/contact">Contact</Link>
                        </li>
                    </ul>

                    <div className="main-menu__right">
                        <Link to="#" className="mobile-nav__toggler">
                            <span></span>
                            <span></span>
                            <span></span>
                        </Link>
{/*                        <Link to="#" onClick={()=>setSearch(true)} className="search-toggler">
                            <i className="icon-magnifying-glass"></i>
                        </Link>*/}
                        <Link to="tel:+44758646998" className="main-menu__cta">
                            <i className="fa fa-phone-alt"></i>
                            <span className="main-menu__cta__text">
                                <b>+ 44 ( 75864 ) 6998</b>
                                REQUEST PICKUP
                            </span>
                        </Link>
                    </div>

                </div>
            </nav>
            {/*<div className={`search-popup ${search && "active"}`}>*/}
            {/*    <div className="search-popup__overlay search-toggler" onClick={()=>setSearch(false)}></div>*/}
            {/*    <div className="search-popup__content">*/}
            {/*        <form action="#">*/}
            {/*            <label htmlFor="search" className="sr-only">search here</label>*/}
            {/*            <input type="text" id="search" placeholder="Search Here..." />*/}
            {/*            <button type="submit" aria-label="search submit" className="thm-btn">*/}
            {/*                <span><i className="icon-magnifying-glass"></i></span>*/}
            {/*            </button>*/}
            {/*        </form>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </>
    )
}

export default Navbar
import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios';
import BG from '../../assets/images/background/contact-one-bg-1-1.png'
import BgImg from  "../../assets/images/background/contact.jpg"
import Breadcumb from '../Breadcumb/Main'

const Contact = () => {

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [isLoading, setLoading] = useState(false);

    function isFormValid() {
        return name && email && message;
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        setLoading(true);
        axios.get('https://us-central1-imgarbage.cloudfunctions.net/sendMail', {
            params: {
                name,
                email,
                message
            }
        })
            .then(function (response) {
                setName("")
                setEmail("")
                setMessage("")
                setLoading(false);
            })
            .catch(function (error) {
                console.log(error);
                setLoading(false);
            })
            .finally(function () {
                // always executed
            });
    }

    return (
        <>
        <Breadcumb Title="Contact" Breadcumb="CONTACT" BgImg={BgImg} />
            <section className="contact-one section-padding--top section-padding--bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <form onSubmit={handleSubmit} className="contact-one__form contact-form-validated">
                                <div className="section-title">
                                    <p className="section-title__text">Contact with us</p>
                                    <h2 className="section-title__title">We are happy to serve you</h2>
                                </div>
                                <div className="row ">
                                    <div className="col-lg-6 col-md-12">
                                        <input type="text" placeholder="Your name*" name="name"
                                               required
                                               value={name}
                                               onChange={(e) => setName(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <input type="email" placeholder="Email address*" name="email"
                                               required
                                               value={email}
                                               onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <textarea name="message" placeholder="Write message*"
                                                  required
                                                  value={message}
                                                  onChange={(e) => setMessage(e.target.value)}
                                        ></textarea>
                                    </div>
                                    <div className="col-md-12">
                                        {
                                            isLoading ? (
                                                <div className="col-lg-6 col-md-12" style={{position: "relative"}}>
                                                    <div className="lds-ellipsis">
                                                        <div></div>
                                                        <div></div>
                                                        <div></div>
                                                        <div></div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <button disabled={!isFormValid()} className="thm-btn contact-one__btn" type="submit">
                                                    <span>send a message</span>
                                                </button>
                                            )
                                        }
                                    </div>
                                </div>
                            </form>
                            <div className="result"></div>
                        </div>
                        <div className="col-lg-4">
                            <div className="contact-one__info wow fadeInRight" data-wow-duration="1500ms"
                                >
                                <div className="contact-one__item">
                                    <h3 className="contact-one__item__title">Address</h3>
                                    <p className="contact-one__item__text">84 Salop Street, <br />
                                        WV3 0SR - Wolverhampton, UK</p>
                                </div>
                                <div className="contact-one__item">
                                    <h3 className="contact-one__item__title">Phone</h3>
                                    <p className="contact-one__item__text">Local: <Link to="tel:+44758646998">075 8646 998</Link><br />
                                        Mobile: <Link to="tel:+44758646998">075 8646 998</Link></p>

                                </div>
                                <div className="contact-one__item">
                                    <h3 className="contact-one__item__title">Email</h3>
                                    <p className="contact-one__item__text"><Link
                                        to="mailto:needhelp@company.com">needhelp@imgarbage.co.uk</Link><br /><Link
                                            to="mailto:inquiry@company.com">inquiry@imgarbage.co.uk</Link></p>

                                </div>
                                <div className="contact-one__item">
                                    <ul className="contact-one__social">
                                        <li><Link to="#"><i className="fab fa-twitter"></i></Link></li>
                                        <li><Link to="#"><i className="fab fa-facebook-f"></i></Link></li>
                                        {/*<li><Link to="#"><i className="fab fa-pinterest-p"></i></Link></li>*/}
                                        <li><Link to="#"><i className="fab fa-instagram"></i></Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="google-map google-map--contact">
                <iframe
                    src="https://maps.google.com/maps?q=84%20Salop%20Street,,%20Wolverhampton,%20WV3%200SR,%20United%20Kingdom&t=&z=13&ie=UTF8&iwloc=&output=embed"
                    className="google-map__one" title='gmap' allowFullScreen></iframe>
            </section>
        </>
    )
}

export default Contact
import React from 'react'
import { Link } from 'react-router-dom'
import Breadcumb from '../../Breadcumb/Main'
import BgImg from  "../../../assets/images/background/careers.jpg"

const Blogs = () => {
    return (
        <>
            <Breadcumb Title="Work at I'm Garbage" Breadcumb="CAREERS" BgImg={BgImg} />
            <section className="section-padding--top section-padding--bottom">
                <div className="container">
                    <div className="row gutter-y-30">
                        <div className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-duration="1500ms"
                            data-wow-delay="000ms">
                            <div className="blog-card-one">
                                <div className="blog-card-one__content">
                                    <div className="blog-card-one__meta">
                                        <div className="blog-card-one__date">
                                            <i className="fa fa-calendar-alt" aria-hidden="true"></i>
                                            April, 10, 2024
                                        </div>
                                        <Link to="#" className="blog-card-one__category">Driver</Link>
                                    </div>
                                    <h3 className="blog-card-one__title"><Link to="/blog-details">LGV 2 Driver / Loader</Link></h3>
                                    <Link to="#" className="blog-card-one__more">
                                        Read More
                                        <i className="fa fa-arrow-right"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-duration="1500ms"
                            data-wow-delay="100ms">
                            <div className="blog-card-one">
                                <div className="blog-card-one__content">
                                    <div className="blog-card-one__meta">
                                        <div className="blog-card-one__date">
                                            <i className="fa fa-calendar-alt" aria-hidden="true"></i>
                                            April, 10, 2024
                                        </div>
                                        <Link to="#" className="blog-card-one__category">Driver</Link>
                                    </div>
                                    <h3 className="blog-card-one__title"><Link to="/blog-details">Van Driver- Reactive Waste</Link></h3>
                                    <Link to="#" className="blog-card-one__more">
                                        Read More
                                        <i className="fa fa-arrow-right"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-duration="1500ms"
                            data-wow-delay="200ms">
                            <div className="blog-card-one">
                                <div className="blog-card-one__content">
                                    <div className="blog-card-one__meta">
                                        <div className="blog-card-one__date">
                                            <i className="fa fa-calendar-alt" aria-hidden="true"></i>
                                            April, 10, 2024
                                        </div>
                                        <Link to="#" className="blog-card-one__category">Driver</Link>
                                    </div>
                                    <h3 className="blog-card-one__title"><Link to="/blog-details">LGV 2 Driver - Part Time</Link></h3>
                                    <Link to="#" className="blog-card-one__more">
                                        Read More
                                        <i className="fa fa-arrow-right"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-duration="1500ms"
                            data-wow-delay="300ms">
                            <div className="blog-card-one">
                                <div className="blog-card-one__content">
                                    <div className="blog-card-one__meta">
                                        <div className="blog-card-one__date">
                                            <i className="fa fa-calendar-alt" aria-hidden="true"></i>
                                            April, 10, 2024
                                        </div>
                                        <Link to="#" className="blog-card-one__category">Corporate</Link>
                                    </div>
                                    <h3 className="blog-card-one__title"><Link to="/blog-details">Waste Management Executive</Link></h3>
                                    <Link to="#" className="blog-card-one__more">
                                        Read More
                                        <i className="fa fa-arrow-right"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        {/*<div className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-duration="1500ms"*/}
                        {/*    data-wow-delay="400ms">*/}
                        {/*    <div className="blog-card-one">*/}
                        {/*        <div className="blog-card-one__content">*/}
                        {/*            <div className="blog-card-one__meta">*/}
                        {/*                <div className="blog-card-one__date">*/}
                        {/*                    <i className="fa fa-calendar-alt" aria-hidden="true"></i>*/}
                        {/*                    April, 10, 2024*/}
                        {/*                </div>*/}
                        {/*                <Link to="#" className="blog-card-one__category">Corporate</Link>*/}
                        {/*            </div>*/}
                        {/*            <h3 className="blog-card-one__title"><Link to="/blog-details">Waste Management Executive</Link></h3>*/}
                        {/*            <Link to="#" className="blog-card-one__more">*/}
                        {/*                Read More*/}
                        {/*                <i className="fa fa-arrow-right"></i>*/}
                        {/*            </Link>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-duration="1500ms"*/}
                        {/*    data-wow-delay="500ms">*/}
                        {/*    <div className="blog-card-one">*/}
                        {/*        <div className="blog-card-one__content">*/}
                        {/*            <div className="blog-card-one__meta">*/}
                        {/*                <div className="blog-card-one__date">*/}
                        {/*                    <i className="fa fa-calendar-alt" aria-hidden="true"></i>*/}
                        {/*                    April, 10, 2024*/}
                        {/*                </div>*/}
                        {/*                <Link to="#" className="blog-card-one__category">Corporate</Link>*/}
                        {/*            </div>*/}
                        {/*            <h3 className="blog-card-one__title"><Link to="/blog-details">Waste Management Executive</Link></h3>*/}
                        {/*            <Link to="#" className="blog-card-one__more">*/}
                        {/*                Read More*/}
                        {/*                <i className="fa fa-arrow-right"></i>*/}
                        {/*            </Link>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </section>
        </>
    )
}

export default Blogs
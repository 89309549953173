import React from 'react'
import { Link } from 'react-router-dom'
import Breadcumb from '../../Breadcumb/Main'
import BgImg from  "../../../assets/images/background/industries.jpg"

const Projects = () => {
  return (
    <>
    <Breadcumb Title="Industries We Serve" Breadcumb="INDUSTRIES" BgImg={BgImg} />
    <section className="section-padding--bottom section-padding--top">
			<div className="container">
				<div className="row gutter-y-30">
					<div className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-duration="1500ms"
						data-wow-delay="000ms">
						<div className="project-card-one">
							<div className="project-card-one__image">
								<img src="assets/images/projects/hotel.jpg"  width={370} height={470} alt=""/>
								<Link to="#"></Link>
							</div>
							<div className="project-card-one__content">
								<div className="project-card-one__content__inner">
									{/*<p className="project-card-one__text">Hotel & Restaurant</p>*/}
									<h3 className="project-card-one__title"><Link to="/project-details">Hotel & <br/> Restaurant</Link></h3>
									<Link to="#" className="project-card-one__more">
										<i className="fa fa-angle-right"></i>
									</Link>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-duration="1500ms"
						data-wow-delay="100ms">
						<div className="project-card-one">
							<div className="project-card-one__image">
								<img src="assets/images/projects/grocery.jpg" width={370} height={470} alt=""/>
								<Link to="#"></Link>
							</div>
							<div className="project-card-one__content">
								<div className="project-card-one__content__inner">
									{/*<p className="project-card-one__text">IT Technology Solution</p>*/}
									<h3 className="project-card-one__title"><Link to="/project-details">Grocery <br/> Stores</Link></h3>
									<Link to="#" className="project-card-one__more">
										<i className="fa fa-angle-right"></i>
									</Link>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-duration="1500ms"
						data-wow-delay="200ms">
						<div className="project-card-one">
							<div className="project-card-one__image">
								<img src="assets/images/projects/hospital.jpg"  width={370} height={470} alt=""/>
								<Link to="#"></Link>
							</div>
							<div className="project-card-one__content">
								<div className="project-card-one__content__inner">
									{/*<p className="project-card-one__text">IT Technology Solution</p>*/}
									<h3 className="project-card-one__title"><Link to="/project-details">Medical & <br/> Hospital</Link></h3>
									<Link to="#" className="project-card-one__more">
										<i className="fa fa-angle-right"></i>
									</Link>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-duration="1500ms"
						data-wow-delay="300ms">
						<div className="project-card-one">
							<div className="project-card-one__image">
								<img src="assets/images/projects/stadium.jpg" width={370} height={470} alt=""/>
								<Link to="#"></Link>
							</div>
							<div className="project-card-one__content">
								<div className="project-card-one__content__inner">
									{/*<p className="project-card-one__text">IT Technology Solution</p>*/}
									<h3 className="project-card-one__title"><Link to="/project-details">Stadium & <br/> Events</Link></h3>
									<Link to="#" className="project-card-one__more">
										<i className="fa fa-angle-right"></i>
									</Link>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-duration="1500ms"
						data-wow-delay="500ms">
						<div className="project-card-one">
							<div className="project-card-one__image">
								<img src="assets/images/projects/theatre.jpg" width={370} height={470} alt=""/>
								<Link to="#"></Link>
							</div>
							<div className="project-card-one__content">
								<div className="project-card-one__content__inner">
									{/*<p className="project-card-one__text">IT Technology Solution</p>*/}
									<h3 className="project-card-one__title"><Link to="/project-details">Mall & <br/> Theatre</Link></h3>
									<Link to="#" className="project-card-one__more">
										<i className="fa fa-angle-right"></i>
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
    </>
  )
}

export default Projects
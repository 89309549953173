import React, {useEffect, useState} from 'react'
import {Link, useLocation} from 'react-router-dom'
import Logo from '../../assets/images/logo-dark.png'

const Footer = () => {
    const location = useLocation();
    const [footerPadding, setfooterPadding] = useState(false);
    useEffect(() => {
        if (location.pathname === "/" || location.pathname === "/service-01") {
            setfooterPadding(true)
        } else {
            setfooterPadding(false)
        }
    }, [location])

    return (
        <>
            <div
                className={`footer-main section-padding--bottom ${footerPadding ? "footer-main--top-padding" : "section-padding--top"}`}>
                <div className="container">
                    <div className="row gutter-y-30">
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="footer-widget footer-widget--about">
                                <Link to="/" className="footer-widget__logo">
                                    <img src={Logo} height="50" alt="I'm Garbage"/>
                                </Link>
                                <p className="footer-widget__text">
                                    We are your local recycling & waste partner collecting across West
                                    Midlands from bags & wheelie bins to bulk containers.</p>
                                <ul className="footer-widget__social">
                                    <li><Link to="#"><i className="fab fa-twitter"></i></Link></li>
                                    <li><Link to="#"><i className="fab fa-facebook-f"></i></Link></li>
                                    <li><Link to="#"><i className="fab fa-pinterest-p"></i></Link></li>
                                    <li><Link to="#"><i className="fab fa-instagram"></i></Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="footer-widget footer-widget--links">
                                <h3 className="footer-widget__title">Services</h3>
                                <ul className="footer-widget__links">
                                    <li><Link to="/service-01">All Services</Link></li>
                                    <li><Link to="/service-02">Waste removal</Link></li>
                                    <li><Link to="/cyber-security">Dumpster rental</Link></li>
                                    <li><Link to="/qa-testing">On-Demand collection</Link></li>
                                    <li><Link to="/qa-testing">Autonomous collection</Link></li>
                                    <li><Link to="/infrastructure-plan">Zero waste</Link></li>
                                    <li><Link to="/it-consultant">Technology</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="footer-widget footer-widget--newsletter">
                                <h3 className="footer-widget__title">Subscribe</h3>
                                <p className="footer-widget__text">Sign up to receive the latest articles</p>

                                <form action="#" className="footer-widget__newsletter mc-form"
                                      data-url="ADD_YOUR_MAILCHIMP_FORM_URL_HERE">
                                    <input type="email" name="EMAIL" placeholder="Enter Your Email"/>
                                    <button className="thm-btn footer-widget__newsletter__btn"><span>Register</span>
                                    </button>
                                </form>
                                <div className="mc-form__response"></div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="footer-widget footer-widget--contact">
                                <h3 className="footer-widget__title">Contact</h3>
                                <ul className="footer-widget__contact">
                                    <li>
                                        <i className="fa fa-phone" style={{color: "#b10f3c"}}></i>
                                        <Link to="tel:+44758646998">+ 44 ( 75864 ) 6998</Link>
                                    </li>
                                    <li>
                                        <i className="fa fa-envelope" style={{color: "#b10f3c"}}></i>
                                        <Link to="mailto:needhelp@company.com">needhelp@imgarbage.co.uk</Link>
                                    </li>
                                    <li>
                                        <i className="fa fa-map-marker-alt" style={{color: "#b10f3c"}}></i>
                                        84 Salop Street, WV3 0SR
                                        <br/> Wolverhampton, UK
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="container">
                    <p>&copy; All Copyright <span className="dynamic-year"></span> by I'm Garbage Ltd.</p>
                </div>
            </div>
        </>
    )
}

export default Footer